@tailwind base;
@tailwind components;
@tailwind utilities;


.almarai-light {
    font-family: "Almarai", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .almarai-regular {
    font-family: "Almarai", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .almarai-bold {
    font-family: "Almarai", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .almarai-extrabold {
    font-family: "Almarai", sans-serif;
    font-weight: 800;
    font-style: normal;
  }

  .h-inh {
    height: inherit;
  }
  
  .rtl {
    direction: rtl;
  }
  .ltr {
    direction: ltr;
  }
  
  .h1-bold {
    font-size: 28px;
    font-weight: 600;
  }
  .sh-3 {
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.1);
    /* -webkit-box-shadow: 0px 5px 19px 0px rgba(0,0,0,0.3); */
    /* -moz-box-shadow: 0px 5px 19px 0px rgba(0,0,0,0.3); */
    
  }
  .sh-2 {
    box-shadow: 6px 6px 54px 0px #0000000D;
    /* -webkit-box-shadow: 0px 5px 19px 0px rgba(0,0,0,0.3); */
    /* -moz-box-shadow: 0px 5px 19px 0px rgba(0,0,0,0.3); */
    
  }

  .loader {
    width: 3.5em;
    height: 4em;
    border: 3px solid transparent;
    border-top-color: #0D8F75;
    border-bottom-color: #0D8F75;
    border-radius: 50%;
    animation: spin-stretch 2s ease infinite;
  }
  
  @keyframes spin-stretch {
    50% {
      transform: rotate(360deg) scale(0.4, 0.33);
      border-width: 8px;
    }
    100% {
      transform: rotate(720deg) scale(1, 1);
      border-width: 3px;
    }
  }