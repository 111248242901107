.tiltle {
  @apply mx-1 md:mx-3 text-[24px] font-[700] text-[#0D8F75];
}
.subTitle {
  @apply text-[18px] font-[600] text-[#0D8F75];
}
.subText {
  @apply my-2 text-[16px] font-[400] text-[#0D8F75];
}
.subtext {
  @apply text-[16px] font-[500] text-[#0D0C22] my-2;
}
.inputTitle {
  @apply text-[16px] font-[700] text-[#064035] my-2;
}
.input {
  @apply bg-white h-[50px] outline-none p-2 rounded-md border text-[16px] w-full md:w-[250px] lg:w-[300px] text-[#064035] my-2;
}

.input2 {
  @apply my-2 outline-none border rounded-md w-full   p-2;
}

.mainBtn {
  @apply w-[300px] h-[50px] rounded-md bg-[#0D8F75] text-white font-[600] m-2 text-[15px];
}
.mainBtn2 {
  @apply  h-[50px] rounded-md bg-[#0D8F75] text-white font-[600] my-2 text-[15px];
}
.secondBtn {
  @apply w-[100px] h-[50px] rounded-md bg-[#0D8F75] text-white font-[600] m-2 text-[15px];
}
.deleteBtn {
  @apply w-[300px] h-[50px] rounded-md bg-[#FF3939] text-white font-[600] m-2 text-[15px];
}

table {
  @apply bg-white w-full overflow-x-auto my-6;
}
table th {
  @apply p-2 text-[10px] md:text-[16px] lg:text-[20px] font-[700] text-[#0D8F75];
}
table tbody tr td {
  @apply p-2 text-[10px] md:text-[14px] lg:text-[18px] font-[600] text-[#000000] text-center;
}
table tbody tr {
  @apply border-b;
}
table tbody tr:last-child {
  @apply border-b-0;
}
